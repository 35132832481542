


















import {Component, Vue} from 'vue-property-decorator';

@Component({
    name: 'NotFound',
})

export default class NotFound extends Vue {
    public mounted(): void {
        this.$nextTick(() => {
            setTimeout(() => this.$router.push({name: 'Home'}), 5000);
        });
    }
}
